/** @format */

import { utilityCreateIntlProvider } from '../../i18n/createProvider';
import { reduceLocales } from '../../i18n/utils/reduce-locales';
import { BUILD_CONFIG } from '../build_config';
import { TLocale } from './types';

export const NAME_APP = 'DoveVivo';
export const NAME_PORTAL_CODE = 'DV';

export const API_KEY_AUTHENTICATION_STAGING = '42ca833693dc4edf85a85ca50e953447';
export const API_KEY_AUTHENTICATION_PRODUCTION = '1953d92aa4964b8396c326017fc0e27b';
export const API_KEY_AUTHENTICATION_DEV = '46ba5a1f044c42d2be43ea41d8e9f5a9';
export const API_KEY_DOVEVIVO = '9a0bbdb2-4d56-405d-bafe-7e5ff409dd4c';
export const API_KEY_MAPBOX =
  'pk.eyJ1IjoiZG92ZXZpdm8iLCJhIjoiY2tvdmMzMGt5MDZ2eTJvcGU2YmE4MmwyeCJ9.RIUyxTNqYKaknZY-XDetFg';

export const API_KEY_STRIPE =
  process.env.GATSBY_ENV === 'production'
    ? 'pk_live_51IvGPvKWn7Unfxwg1mBC15oKTPO8sO6zjebOJetBKxToB7CSXQOL3OZGD2QIOnjVRsik7m4b5Mu72yp3k8z7JbYQ00DIMS7jYk'
    : 'pk_test_51IvGPvKWn7Unfxwg2nZBPuaRthtygkZ6Nza0hx1aNhxzk7DMB6SUDqZfmPlvLA1dgnpZmhTfRncE9R9gTTez6Uvr00iy7Y6fCv';

export const API_KEY_STRIPE_SPAIN =
  process.env.GATSBY_ENV === 'production'
    ? 'pk_live_51J3KRtEdTXrBpgMAz2V9QIdv3JVUnVqlq57HBkyj1LI8Ln8UOxzoIddc5ZWW71GglHeZ1Cl66W3KBS8pLf4XSQC900Z4j7LLQc'
    : 'pk_test_51J3KRtEdTXrBpgMAnsMdts5EvzYIZT2BvVKaFARGHWq57c1bjAEVDPEAKYAUK7yBISq5crH5T6ftFyy9t8q6X5UR006lbVWEA2';

// #region ::: GET CONFIG ENVIRONMENT
// DEV BOOKING API: https://as-dv-api-booking-development.azurewebsites.net
// STAGING BOOKING API: https://am-dovevivo-api-staging.azure-api.net/booking
// PROD BOOKING API: https://am-booking-api.azure-api.net/booking

// TODO: base urls can be removed from here since we have the DV_BASE_URL function
const getConfig = () => {
  switch (process.env.GATSBY_ENV) {
    case 'production':
      return {
        baseIT: 'https://www.dovevivo.it/it/',
        baseEN: 'https://www.dovevivo.com/en/',
        booking: 'https://am-booking-api.azure-api.net/booking',
      };
    case 'staging':
      return {
        baseIT: 'https://test.dovevivo.it/it/',
        baseEN: 'https://test.dovevivo.com/en/',
        booking: 'https://am-dovevivo-api-staging.azure-api.net/booking',
      };
    case 'develop':
      return {
        baseIT: 'https://dev.dovevivo.it/it/',
        baseEN: 'https://dev.dovevivo.com/en/',
        booking: 'https://am-dovevivo-api-staging.azure-api.net/booking',
        //booking: 'https://am-dovevivo-api-development.azure-api.net/booking',
      };
    default:
      throw new Error(`Invalid GATSBY_ENV: ${process.env.GATSBY_ENV}`);
  }
};
// #endregion

export const Environment = {
  baseIT: getConfig().baseIT,
  baseEN: getConfig().baseEN,
  booking: getConfig().booking,
};

export const BUILD_LOCALES = BUILD_CONFIG.buildLocales();
export const ALL_LOCALES = BUILD_CONFIG.allLocales;
export const REPORTER_PAGES = ['poi', 'search', 'districts'] as const;
export const TLD = (locale: TLocale) => (locale === 'it' ? 'it' : 'com');
export const DV_BASE_URL = (locale: TLocale) => `https://www.dovevivo.${TLD(locale)}/${locale}/`;

export const MAX_CONCURRENT_PROMISES: number | null = null;

export const SITEMAPS = ['sitemap_rooms', 'sitemap_cities_and_pages', 'sitemap_blog'];

export const sitemapFileNamesFormatted = (options?: { allLocales?: boolean; locales?: TLocale[] }) => {
  const { intls } = utilityCreateIntlProvider();

  return SITEMAPS.map(sitemap => reduceLocales(oneLocale => intls[oneLocale].formatMessage({ id: sitemap }), options));
};

export const BLOG_LOCALES_DISABLED = ['es'];
